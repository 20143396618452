import React, { useState, useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import Box from "../../components/box"
import Stack from "../../components/stack"
import NextButton from "../../components/next-button"
import { Stage, Layer, Image as KonvaImage, Group } from "react-konva"
import useImage from "use-image"

const STAGE_WIDTH = 1000
const STAGE_HEIGHT = 1608
const STAGE_HEIGHT_CROPPED = 1000
const MAGNET_SIZE = 176

export const backgroundImage = graphql`
  fragment backgroundImage on File {
    childImageSharp {
      gatsbyImageData(width: 1000, layout: FIXED)
    }
  }
`

export const magnetImage = graphql`
  fragment magnetImage on File {
    childImageSharp {
      gatsbyImageData(width: 176, layout: FIXED)
    }
  }
`

const Magnet = ({ alt, position, src, scale }) => {
  const [dragState, setDragState] = useState({
    isDragging: false,
    ...position,
  })
  const [image] = useImage(src)
  if (!image) {
    return null
  }

  return (
    <KonvaImage
      alt={alt}
      image={image}
      x={dragState.x}
      y={dragState.y}
      dragBoundFunc={(pos) => {
        const scaledMagnetSize = scale * MAGNET_SIZE
        const scaledStageWidth = scale * STAGE_WIDTH
        const scaledStageHeight = scale * STAGE_HEIGHT

        const x = Math.floor(
          Math.max(
            pos.x + scaledMagnetSize > scaledStageWidth
              ? scaledStageWidth - scaledMagnetSize
              : pos.x,
            0
          )
        )
        const y = Math.floor(
          Math.max(
            pos.y + scaledMagnetSize > scaledStageHeight
              ? scaledStageHeight - scaledMagnetSize
              : pos.y,
            0
          )
        )
        return {
          x,
          y,
        }
      }}
      draggable
      onDragStart={() => {
        setDragState((state) => ({
          ...state,
          isDragging: true,
        }))
      }}
      onDragEnd={(e) => {
        setDragState((state) => ({
          ...state,
          isDragging: false,
          x: e.target.x(),
          y: e.target.y(),
        }))
      }}
      shadowColor={"black"}
      shadowBlur={20}
      shadowOffset={{ x: 0, y: 0 }}
      shadowOpacity={dragState.isDragging ? 0.8 : 0.6}
    />
  )
}

const HoelderlinLiebtMagnete = () => {
  const containerRef = useRef(null)
  const stageRef = useRef(null)
  const groupRef = useRef(null)
  const [scale, setScale] = useState(1)

  const data = useStaticQuery(graphql`
    query {
      tafel: file(relativePath: { eq: "hoelderlin-liebt/magnete/tafel.jpg" }) {
        ...backgroundImage
      }
      magnet01: file(relativePath: { eq: "hoelderlin-liebt/magnete/01.png" }) {
        ...magnetImage
      }
      magnet02: file(relativePath: { eq: "hoelderlin-liebt/magnete/02.png" }) {
        ...magnetImage
      }
      magnet03: file(relativePath: { eq: "hoelderlin-liebt/magnete/03.png" }) {
        ...magnetImage
      }
      magnet04: file(relativePath: { eq: "hoelderlin-liebt/magnete/04.png" }) {
        ...magnetImage
      }
      magnet05: file(relativePath: { eq: "hoelderlin-liebt/magnete/05.png" }) {
        ...magnetImage
      }
      magnet06: file(relativePath: { eq: "hoelderlin-liebt/magnete/06.png" }) {
        ...magnetImage
      }
      magnet07: file(relativePath: { eq: "hoelderlin-liebt/magnete/07.png" }) {
        ...magnetImage
      }
      magnet08: file(relativePath: { eq: "hoelderlin-liebt/magnete/08.png" }) {
        ...magnetImage
      }
      magnet09: file(relativePath: { eq: "hoelderlin-liebt/magnete/09.png" }) {
        ...magnetImage
      }
      magnet10: file(relativePath: { eq: "hoelderlin-liebt/magnete/10.png" }) {
        ...magnetImage
      }
      magnet11: file(relativePath: { eq: "hoelderlin-liebt/magnete/11.png" }) {
        ...magnetImage
      }
      magnet12: file(relativePath: { eq: "hoelderlin-liebt/magnete/12.png" }) {
        ...magnetImage
      }
      magnet13: file(relativePath: { eq: "hoelderlin-liebt/magnete/13.png" }) {
        ...magnetImage
      }
      magnet14: file(relativePath: { eq: "hoelderlin-liebt/magnete/14.png" }) {
        ...magnetImage
      }
      magnet15: file(relativePath: { eq: "hoelderlin-liebt/magnete/15.png" }) {
        ...magnetImage
      }
      magnet16: file(relativePath: { eq: "hoelderlin-liebt/magnete/16.png" }) {
        ...magnetImage
      }
    }
  `)

  const [tafelImage] = useImage(getSrc(data.tafel))

  useEffect(() => {
    if (containerRef?.current === null || stageRef?.current === null) {
      return
    }
    const resizeCanvas = () => {
      const scale = containerRef.current.offsetWidth / STAGE_WIDTH
      setScale(scale)
      stageRef.current.width(STAGE_WIDTH * scale)
      stageRef.current.height(STAGE_HEIGHT * scale)
      stageRef.current.scale({ x: scale, y: scale })
      stageRef.current.draw()
    }

    resizeCanvas()
    if (window) {
      window.addEventListener("resize", resizeCanvas)
    }
  }, [containerRef, stageRef, setScale])

  const onClickSave = (event) => {
    if (!stageRef?.current) {
      return
    }

    const clonedStage = stageRef.current.clone()
    const group = clonedStage.find("#maingroup")

    // rescale cloned stage and crop for saved image
    clonedStage.scale({ x: 1, y: 1 })
    clonedStage.width(STAGE_WIDTH)
    clonedStage.height(STAGE_HEIGHT_CROPPED)
    clonedStage.draw()
    group.clip({
      x: 0,
      y: 0,
      width: STAGE_WIDTH,
      height: STAGE_HEIGHT_CROPPED,
    })
    clonedStage.draw()
    const data = clonedStage.toDataURL()
    const name = "mein-bildgedicht.png"

    // open link with data
    const link = document.createElement("a")
    link.download = name
    link.href = data
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Box>
      <Stack>
        <Box bg="muted" p={6}>
          <Box ref={containerRef}>
            <Stage width={STAGE_WIDTH} height={STAGE_HEIGHT} ref={stageRef}>
              <Layer>
                <Group ref={groupRef} id="maingroup">
                  <KonvaImage x={0} y={0} image={tafelImage} />

                  <Magnet
                    position={{ x: 1 * 20 + 0 * MAGNET_SIZE, y: 1020 }}
                    src={getSrc(data.magnet01)}
                    scale={scale}
                    alt=""
                  />
                  <Magnet
                    position={{ x: 2 * 20 + 1 * MAGNET_SIZE, y: 1020 }}
                    src={getSrc(data.magnet02)}
                    scale={scale}
                    alt=""
                  />
                  <Magnet
                    position={{ x: 3 * 20 + 2 * MAGNET_SIZE, y: 1020 }}
                    src={getSrc(data.magnet03)}
                    scale={scale}
                    alt=""
                  />
                  <Magnet
                    position={{ x: 4 * 20 + 3 * MAGNET_SIZE, y: 1020 }}
                    src={getSrc(data.magnet04)}
                    scale={scale}
                    alt=""
                  />
                  <Magnet
                    position={{ x: 5 * 20 + 4 * MAGNET_SIZE, y: 1020 }}
                    src={getSrc(data.magnet05)}
                    scale={scale}
                    alt=""
                  />
                  <Magnet
                    position={{ x: 1 * 20 + 0 * MAGNET_SIZE, y: 1216 }}
                    src={getSrc(data.magnet06)}
                    scale={scale}
                    alt=""
                  />
                  <Magnet
                    position={{ x: 2 * 20 + 1 * MAGNET_SIZE, y: 1216 }}
                    src={getSrc(data.magnet07)}
                    scale={scale}
                    alt=""
                  />
                  <Magnet
                    position={{ x: 3 * 20 + 2 * MAGNET_SIZE, y: 1216 }}
                    src={getSrc(data.magnet08)}
                    scale={scale}
                    alt=""
                  />
                  <Magnet
                    position={{ x: 4 * 20 + 3 * MAGNET_SIZE, y: 1216 }}
                    src={getSrc(data.magnet09)}
                    scale={scale}
                    alt=""
                  />
                  <Magnet
                    position={{ x: 5 * 20 + 4 * MAGNET_SIZE, y: 1216 }}
                    src={getSrc(data.magnet10)}
                    scale={scale}
                    alt=""
                  />
                  <Magnet
                    position={{ x: 88 + 1 * 20 + 0 * MAGNET_SIZE, y: 1412 }}
                    src={getSrc(data.magnet11)}
                    scale={scale}
                    alt=""
                  />
                  <Magnet
                    position={{ x: 88 + 2 * 20 + 1 * MAGNET_SIZE, y: 1412 }}
                    src={getSrc(data.magnet12)}
                    scale={scale}
                    alt=""
                  />
                  <Magnet
                    position={{ x: 88 + 3 * 20 + 2 * MAGNET_SIZE, y: 1412 }}
                    src={getSrc(data.magnet13)}
                    scale={scale}
                    alt=""
                  />
                  <Magnet
                    position={{ x: 88 + 4 * 20 + 3 * MAGNET_SIZE, y: 1412 }}
                    src={getSrc(data.magnet14)}
                    scale={scale}
                    alt=""
                  />
                </Group>
              </Layer>
            </Stage>
          </Box>
        </Box>
        <NextButton icon="download" onClick={onClickSave}>
          Bild speichern
        </NextButton>
      </Stack>
    </Box>
  )
}

export default HoelderlinLiebtMagnete
