import React from "react" // eslint-disable-line no-unused-vars
import { useStaticQuery, graphql } from "gatsby"

import Breadcrumb from "../../../components/breadcrumb"
import Paragraph from "../../../components/paragraph"
import Heading from "../../../components/heading"
import HoelderlinLiebtNavigation from "../../../content/hoelderlin-liebt/navigation"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import SectionSpacing from "../../../components/section-spacing"
import Stack from "../../../components/stack"
import HoelderlinLiebtMagnete from "../../../content/hoelderlin-liebt/magnete"
import VideoPlayer from "../../../components/video-player"
import Constrain from "../../../components/constrain"

const HoelderlinLiebtHaelftePage = () => {
  const data = useStaticQuery(
    graphql`
      {
        ogImage: file(relativePath: { eq: "og_images/hoelderlin-liebt.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 600, layout: FIXED)
          }
        }
        lotte: file(relativePath: { eq: "hoelderlin-liebt/lotte-zimmer.jpg" }) {
          ...largeImage
        }
        ernst: file(relativePath: { eq: "hoelderlin-liebt/ernst-zimmer.jpg" }) {
          ...largeImage
        }
        berichtZimmer: file(
          relativePath: {
            eq: "dauerausstellung/01-die-linien-des-lebens/bericht-zimmer.jpg"
          }
        ) {
          ...largeImage
        }
        posterHaelfte: file(
          relativePath: { eq: "hoelderlin-liebt/poster-haelfte-des-lebens.jpg" }
        ) {
          ...largeImage
        }
      }
    `
  )

  return (
    <Layout backdrop="hoelderlin-liebt">
      <Seo
        title="Hölderlins Hälften des Lebens"
        description="In den ersten 36 Jahren seines Lebens ist Hölderlin viel unterwegs. Er will die Welt kennenlernen. Dann wird Hölderlin krank. Er kommt in ein Krankenhaus und wird dort 231 Tage behandelt. Die letzten 36 Jahre seines Lebens verbringt er dann im Turm bei der Familie Zimmer."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <SectionSpacing>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Digital",
                link: "/digital",
              },
              {
                title: "Sonderausstellungen",
                link: "/sonderausstellungen",
              },
              {
                title: "Hölderlin liebt",
                link: "/sonderausstellungen/hoelderlin-liebt",
              },
              {
                title: "Hölderlins Hälften des Lebens",
                link: "/sonderausstellungen/hoelderlin-liebt/haelfte-des-lebens",
              },
            ]}
          />
          <PageTitle>Hölderlins Hälften des Lebens</PageTitle>
          <Paragraph dropcap={true}>
            In den ersten 36 Jahren seines Lebens <br />
            ist Hölderlin viel unterwegs. <br />
            Er will die Welt kennenlernen. <br />
            Nach dem Studium wechselt er häufig die Arbeitsstellen und Wohnorte.{" "}
            <br />
            Dann wird Hölderlin krank. <br />
            Er kommt in ein Krankenhaus und wird dort 231 Tage behandelt. <br />
            Die letzten 36 Jahre seines Lebens <br />
            verbringt er dann im Turm bei der Familie Zimmer. <br />
            Sein neues Zuhause verlässt er nur noch selten für Spaziergänge in
            der Nähe.
          </Paragraph>
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            Das Gedicht
          </Heading>
          <Paragraph>
            ›Hälfte des Lebens‹ ist heute das bekannteste Gedicht von Hölderlin.{" "}
            <br />
            Er hat es mit 30 Jahren geschrieben. <br />
            Am Anfang zeigt uns Hölderlin einen Park oder einen Garten. <br />
            Es ist Spät-Sommer: <br />
            Die reifen Birnen hängen an den Bäumen.
            <br />
            Es duftet nach wilden Rosen. <br />
            Der Tag ist so schön wie ein Gemälde. <br />
            Auf dem See schwimmen Schwäne. <br />
            In Gedichten tauchen Schwäne meistens auf, <br />
            wenn ein Dichter von sich selbst spricht. <br />
            In der zweiten Strophe ist alles anders. <br />
            Der Sommer ist vorbei. <br />
            Die Kälte ist kaum auszuhalten. <br />
            Es bleibt nur ein einsamer, eisiger Winter.
          </Paragraph>
        </Stack>
        <Stack>
          <Heading as="h2" level={3}>
            Ein Gedicht wird zum Bild
          </Heading>
          <Paragraph>
            Ein inklusiver Kunst-Kurs der Lebenshilfe Tübingen <br />
            hat das Gedicht in Bilder übersetzt.
            <br />
            Hier können Sie <br />
            die Landschaften aus dem Gedicht <br />
            selbst nachbauen.
          </Paragraph>
          <Constrain>
            <HoelderlinLiebtMagnete />
          </Constrain>
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            Hölderlins Gedicht ›Hälfte des Lebens‹ in Gebärdensprache
          </Heading>
          <VideoPlayer
            src="hoelderlin-liebt/haelfte-des-lebens"
            poster={data.posterHaelfte}
          />
        </Stack>
        <HoelderlinLiebtNavigation />
      </SectionSpacing>
    </Layout>
  )
}

export default HoelderlinLiebtHaelftePage
